import { Box, Modal, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../../Components/Carousel/Carousel.css";
import CloseIcon from "../../assets/ToolTips/Frame 173.png";
import NasDaq from "../../assets/Group 60.png";
import Cookies from "js-cookie";

const WalkThroughModal = ({
  setHighLightGrid,
  openWalkthroughModal,
  setOpenWalkthroughModal,
  user,
  handleFitstTime,
}: any) => {
  const isMobile = useMediaQuery("(max-width:1000px)");

  const sliderRef = React.useRef<any>(null);

  const tourDialog = [
    `${require("../../assets/ToolTips/Tooltip (1).png")}`,
    `${require("../../assets/ToolTips/Tooltip.png")}`,
    `${require("../../assets/ToolTips/Tooltip (2).png")}`,
    `${require("../../assets/ToolTips/Tooltip (3).png")}`,
    `${require("../../assets/ToolTips/Tooltip (4).png")}`,
    `${require("../../assets/ToolTips/Tooltip (5).png")}`,
    `${require("../../assets/ToolTips/Tooltip (6).png")}`,
    `${require("../../assets/ToolTips/Tooltip (7).png")}`,
    `${require("../../assets/ToolTips/Tooltip (9).png")}`,
    `${require("../../assets/ToolTips/Tooltip (10).png")}`,
  ];

  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handleAfterChange = (index: number) => {
    setCurrentSlide(index);
  };

  const handleBeforeChange = (current: number, next: number) => {
    setCurrentSlide(next);
  };

  const settings = {
    dots: isMobile ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    useCSS: true,
    afterChange: handleAfterChange,
    slickNext: handleGridHighlight(currentSlide),
    beforeChange: handleBeforeChange,

    CenterMode: true,
    arrows: true,
    accessibility: true,
    slickPrev: true,
    dotsClass: "slick-dots",
    leftKey: "ArrowLeft",
    rightKey: "ArrowRight",
  };

  function handleGridHighlight(currentSlide: number) {
    if (currentSlide === 0) {
      setHighLightGrid("");
    } else if (currentSlide === 1) {
      setHighLightGrid("");
    } else if (currentSlide === 2) {
      setHighLightGrid("portfolio");
    } else if (currentSlide === 3) {
      setHighLightGrid("buySell");
    } else if (currentSlide === 4) {
      setHighLightGrid("playerDeck");
    } else if (currentSlide === 5) {
      setHighLightGrid("players");
    } else if (currentSlide === 6) {
      setHighLightGrid("marquee");
    } else if (currentSlide === 7) {
      setHighLightGrid("eventCard");
    } else if (currentSlide === 8) {
      setHighLightGrid("cash");
    } else if (currentSlide === 9) {
      setHighLightGrid("dice");
    } else if (!openWalkthroughModal) {
      setHighLightGrid("");
    }
  }

  const previousSlide = () => {
    sliderRef.current.slickPrev();
  };

  const nextSlide = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (e.key === "ArrowLeft") {
        previousSlide();
      } else if (e.key === "ArrowRight") {
        nextSlide();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <Modal
      open={openWalkthroughModal}
      onClose={() => {
        setOpenWalkthroughModal(false);
        handleFitstTime(user);
        setHighLightGrid("");
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: { xs: "26rem", lg: "52rem" },
        marginTop: "5rem",
      }}
      disableAutoFocus={true}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(0, 0,0, 0.5)",
          },
        },
      }}
    >
      <Paper
        sx={{
          width: { xs: "500px", lg: "1000px" },
          backgroundColor: "rgba(0,0,0,0)",
          display: "flex",
          position: "relative",
        }}
      >
        <Box
          component="img"
          src={require("../../assets/ToolTips/Big Tech Breakup Card 1.png")}
          alt=""
          sx={{
            display: `${currentSlide === 7 ? "block" : "none"}`,
            height: { xs: "170px", lg: "340px" },
            width: { xs: "125px", lg: "250px" },
            position: "absolute",
            bottom: "40%",
            left: "-58%",
          }}
        />

        <Box
          component="img"
          src={tourDialog[currentSlide]}
          alt=""
          sx={{
            width: { xs: "100px", lg: "25vh" },
            position: "absolute",
            bottom: `${currentSlide === 5 ? "50%" : "85%"}`,
            left: { xs: "10%", lg: "15vh" },
            zIndex: 9999,
          }}
        />

        <Box
          sx={{
            width: { xs: "20%", lg: "30%" },
            marginLeft: { xs: "125px", lg: "30vh" },
            marginBottom: { xs: "100px", lg: "10vh" },
          }}
        >
          <Slider {...settings} ref={sliderRef}>
            {Array.from({ length: 10 }).map((_, i) => (
              <div
                key={i}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component="img"
                  src={NasDaq}
                  alt=""
                  sx={{
                    width: { xs: "60px", lg: "20vh" },
                    margin: "auto",
                  }}
                />
              </div>
            ))}
          </Slider>
        </Box>
        <Box
          component="img"
          src={CloseIcon}
          alt=""
          sx={{
            width: { xs: 16, lg: 32 },
            height: { xs: 20.5, lg: 41 },
            cursor: "pointer",
            marginTop: "30px",
          }}
          onClick={() => {
            setOpenWalkthroughModal(false);
            setHighLightGrid("");
            handleFitstTime(user);
          }}
          // onClick={() => {
          //   setOpenWalkthroughModal(false);
          // }}
        />
      </Paper>
    </Modal>
  );
};

export default WalkThroughModal;
