import { Box, Button, Modal, Paper, Slide } from "@mui/material";
import React, { useEffect } from "react";

const TurnOverModal = ({
  openModal1,
  setOpenModal1,
  openModal2,
  setOpenModal2,
  openModal3,
  setOpenModal3,
  roundNo,
  eventCardDetails,
  setOpenResultModal,
  players,
  playerTurn,
  openTurnOverForPlayer,
  setTurnOverForPlayer,
  playEventCard,
  disableEnter,
}: {
  openModal1: boolean;
  setOpenModal1: React.Dispatch<React.SetStateAction<boolean>>;
  openModal2: boolean;
  setOpenModal2: React.Dispatch<React.SetStateAction<boolean>>;
  openModal3: boolean;
  setOpenModal3: React.Dispatch<React.SetStateAction<boolean>>;
  roundNo: number;
  eventCardDetails: any;
  setOpenResultModal: React.Dispatch<React.SetStateAction<boolean>>;
  players: any;
  playerTurn: number;
  openTurnOverForPlayer: boolean;
  setTurnOverForPlayer: React.Dispatch<React.SetStateAction<boolean>>;
  playEventCard: () => void;
  disableEnter: boolean;
}) => {
  const useStyles = {
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    pingpong: {
      animation: "$pingpong 5s ease-in-out infinite",
    },
    "@keyframes pingpong": {
      "0%": {
        transform: "translateX(-50%)",
      },
      "50%": {
        transform: "translateX(0%)",
      },
      "100%": {
        transform: "translateX(-50%)",
      },
    },
  };
  useEffect(() => {
    if (openTurnOverForPlayer) {
      setTimeout(() => {
        setTurnOverForPlayer(false);
      }, 1000);
    }
  }, [openTurnOverForPlayer, setTurnOverForPlayer]);

  function openModal2Func() {
    setOpenModal1(true);
    setTimeout(() => {
      setOpenModal1(false);
      setOpenModal2(true);
      setTimeout(() => {
        setOpenModal2(false);
        if (roundNo === 7) setOpenResultModal(true);
      }, 1000);
    }, 1000);
  }

  useEffect(() => {
    const handleKeyPress = (event: any) => {
      if (event.keyCode === 13 && !disableEnter) {
        const button = document.getElementById("endTurnButtonEventCard");
        if (button) {
          button.click();
          setOpenModal3(false);
          playEventCard();
          openModal2Func();
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [disableEnter]);

  return (
    <>
      {openTurnOverForPlayer ? (
        <Modal
          aria-labelledby="top-modal"
          aria-describedby="top-modal-description"
          closeAfterTransition={true}
          disableEscapeKeyDown={true}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={openTurnOverForPlayer}
          onClose={() => {
            setTurnOverForPlayer(false);
          }}
          disableAutoFocus={true}
        >
          <Slide direction="left" in={openModal1}>
            <div>
              <h1 style={{ color: "white" }}> TURN OVER </h1>
            </div>
          </Slide>
        </Modal>
      ) : (
        <Modal
          aria-labelledby="top-modal"
          aria-describedby="top-modal-description"
          closeAfterTransition={true}
          disableEscapeKeyDown={true}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={openModal1}
          onClose={() => {
            setOpenModal1(false);
            setOpenModal2(true);
            setTimeout(() => {
              setOpenModal2(false);
            }, 1000);
          }}
          disableAutoFocus={true}
        >
          <Slide direction="left" in={openModal1}>
            <div>
              <h1 style={{ color: "white" }}>ROUND OVER</h1>
            </div>
          </Slide>
        </Modal>
      )}

      <Modal
        aria-labelledby="top-modal"
        aria-describedby="top-modal-description"
        closeAfterTransition={true}
        disableEscapeKeyDown={true}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
        open={openModal2}
        onClose={() => {
          setOpenModal2(false);

          if (roundNo === 7) setOpenResultModal(true);
        }}
        disableAutoFocus={true}
      >
        <Slide direction="down" in={openModal2}>
          <div className={`${useStyles.pingpong}`}>
            <h1 style={{ color: "white", fontSize: "90px" }}>
              {roundNo < 7 ? `ROUND: ${roundNo}` : "GAME OVER"}
            </h1>
          </div>
        </Slide>
      </Modal>
      <Modal
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
        }}
        open={openModal3}
        disableAutoFocus={true}
      >
        <Paper
          sx={{
            backgroundColor: "rgba(0,0,0,0)",
            cursor: "pointer",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#2C2C2C",
          }}
        >
          <Box
            component="img"
            src={eventCardDetails?.image}
            sx={{
              height: { xs: 200, lg: 400 },
            }}
            alt=""
          />
          <Button
            onClick={() => {
              setOpenModal3(false);
              playEventCard();
              openModal2Func();
            }}
            id="endTurnButtonEventCard"
            sx={{
              marginTop: 2,
              border: "0px",
              width: { sm: 50, lg: 109 },
              height: { sm: 16, lg: 37 },
              background: "#FFC700",
              boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 20px",
              cursor: "pointer",
              color: "#2C2C2C",
              fontSize: { sm: "10px", lg: "14px" },
              fontWeight: 700,
              "&:hover": {
                backgroundColor: "#FFC700",
              },
            }}
          >
            CONTINUE
          </Button>
        </Paper>
      </Modal>
    </>
  );
};

export default TurnOverModal;
