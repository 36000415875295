import { ThreeDots } from "react-loader-spinner";
import { PlayerModel } from "../shared/models/PlayerModel";
import Dice from "./Dice";
import { Avatar, Box, Grid, Typography, useMediaQuery } from "@mui/material";

export default function Player({
  color,
  avatar,
  playerIndex,
  diceRoller,
  face,
  isRolling,
  players,
  playerTurn,
  botBuyingIndustry,
  botSellingIndustry,
  botBuyingShares,
  botSellingShares,
  icon,
  cardIcon,
  openResultModal,
  disableLoading,
  openProfile,
}: {
  color: string;
  avatar: string;
  playerIndex: number;
  diceRoller: number;
  face: number;
  isRolling: boolean;
  players: Array<PlayerModel>;
  playerTurn: number;
  botBuyingIndustry: string;
  botBuyingShares: number;
  botSellingIndustry: string;
  botSellingShares: number;
  icon: string;
  cardIcon: string;
  openResultModal: boolean;
  disableLoading: boolean;
  openProfile: () => void;
}) {
  const isMobile = useMediaQuery("(max-width:1000px)");

  return (
    <>
      <Grid item sx={{ position: "relative" }}>
        {!openResultModal && (
          <div style={{ position: "absolute", zIndex: 1, right: 0 }}>
            {diceRoller === playerIndex && (
              <Dice face={face} isRolling={isRolling} />
            )}
          </div>
        )}
        <Avatar
          sx={{
            width: { xs: "50px", lg: "15vh" },
            height: { xs: "50px", lg: "15vh" },
            background: color,
            borderRadius: "100px",
            border: "3px solid white",
          }}
          alt="Remy Sharp"
          src={avatar}
          onClick={() => openProfile()}
        />
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: { xs: "8px", lg: "16px" },
              fontWeight: 700,
              paddingTop: { xs: "5px", lg: "10px" },
              fontFamily: "'Urbanist', sans-serif",
              display: "flex",
            }}
          >
            {players[playerIndex].name}
            <Box
              component="img"
              src={icon}
              sx={{
                width: { xs: "3px", lg: "6px" },
                height: { xs: "6px", lg: "12px" },
                paddingBottom: "0px",
                marginLeft: "3px",
                marginTop: { xs: "3px", lg: "5px" },
              }}
              alt=""
            ></Box>
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            marginLeft: 0.7,
            marginTop: 0.7,
          }}
        >
          {!openResultModal && !disableLoading && (
            <ThreeDots
              height="2vh"
              width="2vw"
              color="#87B758"
              visible={playerTurn === playerIndex}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        width="60%"
        item
        container
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item>
          <Typography
            sx={{
              color: "#FFFFFF",
              fontSize: { xs: "6px", lg: "17px" },
              fontWeight: 700,
              display: "flex",
            }}
          >
            {players[playerIndex].action_cards.length}
            <Box
              component="img"
              src={cardIcon}
              sx={{
                width: { xs: "12.5px", lg: "30px" },
                marginTop: -0.4,
              }}
              alt=""
            ></Box>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        width="60%"
        minHeight={isMobile ? "17.5px" : "10vh"}
        item
        container
        justifyContent="space-evenly"
        alignItems="center"
      >
        {playerTurn === playerIndex &&
          ((botBuyingIndustry && botBuyingShares > 0) ||
            (botSellingIndustry && botSellingShares > 0)) && (
            <>
              <Grid item>
                {botBuyingIndustry ? (
                  <Typography
                    sx={{
                      width: { xs: 13, lg: 30 },
                      height: { xs: 13, lg: 30 },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#FFFFFF",
                      border: "2px solid #49E400",
                      borderRadius: "50px",
                      fontWeight: 700,
                      fontSize: { xs: "7px", lg: "14px" },
                      color: "#49E400",
                      marginTop: { xs: -0.5, lg: 0 },
                    }}
                  >
                    +{botBuyingShares}
                  </Typography>
                ) : botSellingIndustry ? (
                  <Typography
                    sx={{
                      width: { xs: 13, lg: 30 },
                      height: { xs: 13, lg: 30 },
                      background: "#FFFFFF",
                      border: "2px solid #E40000",
                      borderRadius: "50px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 700,
                      fontSize: { xs: "7px", lg: "14px" },
                      color: "#E40000",
                      marginTop: { xs: -0.5, lg: 0 },
                    }}
                  >
                    -{botSellingShares}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item>
                {botBuyingIndustry ? (
                  <Box
                    component="img"
                    src={require(`../assets/IndustryIcons/${
                      botBuyingIndustry === "real_estate" || "consumer_good"
                        ? botBuyingIndustry.toUpperCase().replace("_", " ")
                        : botBuyingIndustry.toUpperCase()
                    }.png`)}
                    sx={{
                      height: { xs: "16.5px", lg: "33px" },
                      width: { xs: "16.5px", lg: "33px" },
                    }}
                    alt=""
                  />
                ) : botSellingIndustry ? (
                  <Box
                    component="img"
                    src={require(`../assets/IndustryIcons/${
                      botSellingIndustry === "real_estate" || "consumer_good"
                        ? botSellingIndustry.toUpperCase().replace("_", " ")
                        : botSellingIndustry.toUpperCase()
                    }.png`)}
                    sx={{
                      height: { xs: "16.5px", lg: "33px" },
                      width: { xs: "16.5px", lg: "33px" },
                    }}
                    alt=""
                  />
                ) : null}
              </Grid>
            </>
          )}
      </Grid>
    </>
  );
}
