import { useEffect, useState } from "react";

import { Box, Grid, Typography, useMediaQuery } from "@mui/material";

import { EventCardModel } from "../shared/models/EventCardModel";

import BasicModal from "./Modal/BasicModal";

import ConfirmationModal from "./Modal/ConfirmationModal";

import { PlayerModel } from "../shared/models/PlayerModel";

import QtyModal from "./Modal/QtyModal";
import "../assets/Minecraft.ttf";
import "../Components/Css/HomePage.css";

import UpPng from "../assets/up.png";
import DownPng from "../assets/down.png";
// Action card png
import seetheFutureImage from "../../src/assets/ActionCards/_Design _ Description change/see the futures digital.png";
import GreedIsGoodImage from "../../src/assets/ActionCards/_Design _ Description change/greed is good Digital.png";
import stockBlockImage from "../../src/assets/ActionCards/_Design _ Description change/STOCK BLOCK.png";
import StockDiVidend1 from "../../src/assets/ActionCards/_Design _ Description change/stock dividend 1 Digital.png";
import StockDiVidend2 from "../../src/assets/ActionCards/_Design _ Description change/stock dividend 2 Digital.png";
import StockDiVidend3 from "../../src/assets/ActionCards/_Design _ Description change/stock dividend 3 Digital.png";
import YearEndBonus from "../../src/assets/ActionCards/_Design _ Description change/yean end bonuses Digital.png";
import AssetsSell from "../../src/assets/ActionCards/_Design _ Description change/asset sale Digital.png";
import SalaryIncrease from "../../src/assets/ActionCards/_Design _ Description change/salary increase Digital.png";
import HignProductivity from "../../src/assets/ActionCards/_Design _ Description change/high productivity Digital.png";
import FrozenAssest1 from "../../src/assets/ActionCards/_Design _ Description change/FROZEN ASSET.png";
import FrozenAssest2 from "../../src/assets/ActionCards/_Design _ Description change/FROZEN ASSETS.png";
import RobinHood from "../../src/assets/ActionCards/_Design _ Description change/ROBINHOOD.png";
import StockDumb from "../../src/assets/ActionCards/_Design _ Description change/stock dump digital.png";
import Thief from "../../src/assets/ActionCards/_Design _ Description change/THEIF.png";
import NationDonation from "../../src/assets/ActionCards/_Design _ Description change/nation donation digital.png";
import InsiderPng from "../../src/assets/ActionCards/_Design _ Description change/insider trading digital.png";
import EnergyTaxBenifit from "../../src/assets/ActionCards/_Design _ Description change/energy tax benefit Digital.png";
import TaxRefund from "../../src/assets/ActionCards/_Design _ Description change/tax refund Digital.png";
import PerformancePng from "../../src/assets/ActionCards/_Design _ Description change/performance bonus Digital.png";
import GdpGrowthPng from "../../src/assets/ActionCards/_Design _ Description change/gdp growth Digital.png";
import IpoSeasonPng from "../../src/assets/ActionCards/_Design _ Description change/ipo season Digital.png";
import TrustFundPng from "../../src/assets/ActionCards/_Design _ Description change/trust fund Digital.png";

import Marquee from "react-fast-marquee";
import { ActionCardModel } from "../shared/models/ActionCardModel";

import Dice from "./Dice";
import PopUpActionCardModal from "./Modal/PopUpActionCardModal";
import EventCardModal from "./Modal/EventCardModal";

import PortFolio from "../Components/PortFolio";
import BuySellEndTurn from "./BuySellEndTurn";
import PlayerDeck from "./PlayerDeck";
import Players from "./Players";
import EventCardActionCard from "./EventCardActionCard";
import scoreBoardPng from "../assets/ScoreBoard.png";
import logoutICon from "../assets/logout-16.png";
import {
  initPlayer,
  shuffleDeck,
  initialStockPrices,
  initialEventDeck,
  timeout,
} from "../helpers/helper-functions";
import ProfileModal from "./Modal/ProfileModal";
import { StockModel } from "../shared/models/StockModel";
import ResultModal from "./Modal/ResultModal";
import EndTurnModal from "./Modal/EndTurnModal";
import PlayerSelectionModal from "./Modal/PlayerSelectionModal";
import ActionIndustrySelectionModal from "./Modal/ActionIndustrySelectionModal";
import { accountService } from "../services";
import WalkThroughModal from "./Modal/WalkThroughModal";
import TurnOverModalForPlayer from "./Modal/TurnOverModalForPlayer";

import infoICon from "../assets/info.png";
import RuleBookModal from "./Modal/RuleBookModal";

import StockDividendModal from "./Modal/StockDividendModal";
import LeaderBoard from "./LeaderBoard";
import { Mixpanel } from "../helpers/mixpanel-helper";
import ThirdRoundEmailModal from "./Modal/ThirdRoundEmailModal";
import EndRoundDisccountModal from "./Modal/EndRoundDisccountModal";
import { Redirect, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

let turnsCounter = 1;

const HomePage = () => {
  const [roundNo, setRoundNo] = useState<number>(1);
  const [players, setPlayers] = useState<Array<PlayerModel>>([
    initPlayer("NASDUQ", "player"),
    initPlayer("OWL", "bot"),
    initPlayer("SWAN", "bot"),
    initPlayer("TOUCAN", "bot"),
  ]);
  const [playerTurn, setPlayerTurn] = useState(0);
  const [diceRoller, setDiceRoller] = useState(0);
  const [isRolling, setIsRolling] = useState(false);
  const [face, setFace] = useState(1);
  const [openWalkthroughModal, setOpenWalkthroughModal] = useState(false);

  const [selectPlayerModalOpen, setSelectPlayerModalOpen] = useState(false);
  const [selectedActionPlayer, setSelectedActionPlayer] = useState<
    number | null
  >(null);

  const [selectIndustryModalOpen, setSelectIndustryModalOpen] = useState(false);
  const [selectedActionIndustry, setSelectedActionIndustry] = useState<
    string | null
  >(null);

  const [openStockDividendModal, setOpenStockDividendModal] = useState(false);

  const [playStockBlockBool, setPlayStockBlockBool] = useState<null | boolean>(
    null
  );

  const [playersHistory, setPlayersHistory] = useState<Array<Array<any>>>([]);

  const [diceClick, setDiceClick] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const waitForUserSelectInput = () => {
    return new Promise<number | null>((resolve) => {
      setSelectPlayerModalOpen(true);

      const intervalId = setInterval(() => {
        let promiseUserInput = selectedActionPlayer;
        let promiseSelectPlayerModalOpen = selectPlayerModalOpen;

        setSelectedActionPlayer((oldSelectedActionPlayer) => {
          promiseUserInput = oldSelectedActionPlayer;
          return oldSelectedActionPlayer;
        });

        setSelectPlayerModalOpen((oldSelectPlayerModalOpen) => {
          promiseSelectPlayerModalOpen = oldSelectPlayerModalOpen;
          return oldSelectPlayerModalOpen;
        });

        if (promiseUserInput) {
          clearInterval(intervalId);
          resolve(promiseUserInput);
        }

        if (!promiseSelectPlayerModalOpen) {
          clearInterval(intervalId);
          resolve(null);
        }
      }, 100);
    });
  };

  let playerCashAfterRolldice = 0;

  const handleDiceClick = async (newPlayers: Array<PlayerModel>) => {
    setIsRolling(true);

    await timeout(1000);

    setIsRolling(false);

    const min = 1;
    const max = 6;
    const randomNum = Math.floor(Math.random() * (max - min + 1) + min);
    const cash = (randomNum * 1000000) / 1000000;

    playerCashAfterRolldice = cash;

    for (let i = 0; i < newPlayers.length; i++) {
      newPlayers[i].cash += playerCashAfterRolldice * 1000000;
    }

    setFace(randomNum);

    setTimeout(async () => {
      if (players[playerTurn].type === "player") await drawActionCard(1);
    }, 500);
  };

  const [eventDeck, setEventDeck] =
    useState<Array<EventCardModel>>(initialEventDeck);

  // change the cash of a player with a particular index by a particular amount
  function changePlayerCash(index: number, amount: number) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      newPlayers[index].cash += amount;
      return newPlayers;
    });
  }

  function increaseAllPlayersCash(cash: number) {
    changePlayerCash(playerTurn, cash);
    changePlayerCash((playerTurn + 1) % players.length, cash);
    changePlayerCash((playerTurn + 2) % players.length, cash);
    changePlayerCash((playerTurn + 3) % players.length, cash);
  }

  // increase the cash of the currect player with a given amount
  function increaseCurrentPlayerCash(amount: number) {
    changePlayerCash(playerTurn, amount);
  }

  function actionCardDiscard(playerIndex: number) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];

      let randomActionCardIndex = Math.floor(
        Math.random() * newPlayers[playerIndex].action_cards.length
      );

      newPlayers[playerIndex].action_cards.splice(randomActionCardIndex, 1);

      return newPlayers;
    });
  }

  function actionCardSteal(playerIndex: number) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];

      let randomActionCardIndex = Math.floor(
        Math.random() * newPlayers[playerIndex].action_cards.length
      );

      const stolenActionCard =
        newPlayers[playerIndex].action_cards[randomActionCardIndex];

      newPlayers[playerIndex].action_cards.splice(randomActionCardIndex, 1);
      newPlayers[playerTurn].action_cards.push(stolenActionCard);

      return newPlayers;
    });
  }

  function moneySteal(playerIndex: number) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      const totalCash =
        (newPlayers[playerIndex] as any).cash >= 5000000
          ? 5000000
          : (newPlayers[playerIndex] as any).cash;

      (newPlayers[playerIndex] as any).cash -= totalCash;
      (newPlayers[playerTurn] as any).cash += totalCash;

      return newPlayers;
    });
  }

  const [buttonText, setButtonText] = useState("BUY");
  const [buySellModalOpen, setBuySellModalOpen] = useState(false);
  const [qtyModelOpen, setQtyModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [selectedShare, setSelectedShare] = useState("technology");
  const [selectedNoOfShares, setSelectedNoOfShares] = useState(1);
  const [openEventCardModal, setEventCardModal] = useState(false);
  const [eventCardDetails, setEventCardDetails] =
    useState<EventCardModel | null>(null);
  const [actionCardPopup, setActionCardPopup] = useState(false);
  const [PopupActionCardDetails, setPopupActionCardDetails] = useState({});
  const [openProfile, setOpenProfile] = useState(false);

  const [tickerEventCard, setTickerEventCard] = useState<EventCardModel | null>(
    null
  );

  const waitForEventCardToClose = () => {
    return new Promise<boolean | null>((resolve) => {
      setEventDeck((oldEventDeck) => {
        setEventCardDetails(oldEventDeck[oldEventDeck.length - 1]);
        setEventCardModal(true);
        return oldEventDeck;
      });

      const intervalId = setInterval(() => {
        let promiseUserInput = eventCardModal;

        setEventCardModal((oldOpenEventCardModal) => {
          promiseUserInput = oldOpenEventCardModal;
          return oldOpenEventCardModal;
        });

        if (!promiseUserInput) {
          clearInterval(intervalId);
          resolve(promiseUserInput);
        }
      }, 100);
    });
  };

  const waitForStockBlockResult = (newPopupActionCardDetails: any) => {
    return new Promise<boolean | null>((resolve) => {
      setPlayStockBlockBool(false);
      setActionCardPopup(true);
      setPopupActionCardDetails(newPopupActionCardDetails);

      const intervalId = setInterval(() => {
        let promiseUserInput = playStockBlockBool;
        let promiseSelectPlayerModalOpen = actionCardPopup;

        setPlayStockBlockBool((oldPlayStockBlockBool) => {
          promiseUserInput = oldPlayStockBlockBool;
          return oldPlayStockBlockBool;
        });

        setActionCardPopup((oldActionCardPopup) => {
          promiseSelectPlayerModalOpen = oldActionCardPopup;
          return oldActionCardPopup;
        });

        if (promiseUserInput) {
          clearInterval(intervalId);
          resolve(promiseUserInput);
        }

        if (!promiseSelectPlayerModalOpen) {
          clearInterval(intervalId);
          resolve(null);
        }
      }, 100);
    });
  };

  const playActionCard = (index: number) => {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      newPlayers[playerTurn].action_cards.splice(index, 1);
      newPlayers[playerTurn].moves--;

      return newPlayers;
    });
  };

  async function moneyStealBot() {
    let randomIndex = Math.floor(Math.random() * players.length);
    while (randomIndex === playerTurn) {
      randomIndex = Math.floor(Math.random() * players.length);
    }

    if (randomIndex === 0) {
      const stockBlockBoolResult = await waitForStockBlockResult({
        title: "NATION DONATION",
        body: "Steal $5m in cash from another player",
        image: NationDonation,
        action: async (index: number) => {
          const selectedActionPlayer = await waitForUserSelectInput();

          if (selectedActionPlayer) {
            moneySteal(selectedActionPlayer);
            playActionCard(index);
            setSelectedActionPlayer(null);
          }
        },
        color: "#B75858",
        data: null,
      });

      if (stockBlockBoolResult === true) {
        setPlayers((oldPlayers) => {
          const newPlayers = [...oldPlayers];

          let idx = newPlayers[playerTurn].action_cards.findIndex(
            (p) => p.title == "NATION DONATION"
          );

          //remove idx action card from index player
          newPlayers[playerTurn].action_cards.splice(idx, 1);
          newPlayers[playerTurn].moves -= 1;

          return newPlayers;
        });

        return;
      }
    }

    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      const totalCash =
        (newPlayers[randomIndex] as any).cash >= 5000000
          ? 5000000
          : (newPlayers[randomIndex] as any).cash;

      (newPlayers[randomIndex] as any).cash -= totalCash;
      (newPlayers[playerTurn] as any).cash += totalCash;

      let idx = newPlayers[playerTurn].action_cards.findIndex(
        (p) => p.title == "NATION DONATION"
      );

      //remove idx action card from index player
      newPlayers[playerTurn].action_cards.splice(idx, 1);

      newPlayers[playerTurn].moves -= 1;

      return newPlayers;
    });
  }

  async function actionCardStealBot() {
    let randomIndex = Math.floor(Math.random() * players.length);

    if (randomIndex === 0) {
      const stockBlockBoolResult = await waitForStockBlockResult({
        title: "THIEF!",
        body: "Steal an action card at random from any player's hand",
        image: Thief,
        action: async (index: number) => {
          const selectedActionPlayer = await waitForUserSelectInput();

          if (selectedActionPlayer) {
            actionCardSteal(selectedActionPlayer);
            playActionCard(index);
            setSelectedActionPlayer(null);
          }
        },
        color: "#B75858",
        data: null,
      });

      if (stockBlockBoolResult === true) {
        setPlayers((oldPlayers) => {
          const newPlayers = [...oldPlayers];

          let idx = newPlayers[playerTurn].action_cards.findIndex(
            (p) => p.title == "THIEF!"
          );

          //remove idx action card from index player
          newPlayers[playerTurn].action_cards.splice(idx, 1);
          newPlayers[playerTurn].moves -= 1;

          return newPlayers;
        });

        return;
      }
    }

    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      while (
        randomIndex === playerTurn ||
        newPlayers[randomIndex].action_cards.length === 0
      ) {
        randomIndex = Math.floor(Math.random() * newPlayers.length);
      }

      let randomActionCardIndex = Math.floor(
        Math.random() * newPlayers[randomIndex].action_cards.length
      );

      const stolenActionCard =
        newPlayers[randomIndex].action_cards[randomActionCardIndex];

      newPlayers[randomIndex].action_cards.splice(randomActionCardIndex, 1);
      newPlayers[playerTurn].action_cards.push(stolenActionCard);

      let idx = newPlayers[playerTurn].action_cards.findIndex(
        (p) => p.title == "THIEF!"
      );

      //remove idx action card from index player
      newPlayers[playerTurn].action_cards.splice(idx, 1);

      newPlayers[playerTurn].moves -= 1;

      return newPlayers;
    });
  }

  async function actionCardDiscardBot() {
    let randomIndex = Math.floor(Math.random() * players.length);

    if (randomIndex === 0) {
      const stockBlockBoolResult = await waitForStockBlockResult({
        title: "STOCK DUMP",
        body: "Randomly discard (1) Action Card of another player",
        image: StockDumb,
        action: async (index: number) => {
          const selectedActionPlayer = await waitForUserSelectInput();

          if (selectedActionPlayer) {
            actionCardDiscard(selectedActionPlayer);
            playActionCard(index);
            setSelectedActionPlayer(null);
          }
        },
        color: "#B75858",
        data: null,
      });

      if (stockBlockBoolResult === true) {
        setPlayers((oldPlayers) => {
          const newPlayers = [...oldPlayers];

          let idx = newPlayers[playerTurn].action_cards.findIndex(
            (p) => p.title == "STOCK DUMP"
          );

          //remove idx action card from index player
          newPlayers[playerTurn].action_cards.splice(idx, 1);
          newPlayers[playerTurn].moves -= 1;

          return newPlayers;
        });

        return;
      }
    }

    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];

      while (
        randomIndex === playerTurn ||
        newPlayers[randomIndex].action_cards.length === 0
      ) {
        randomIndex = Math.floor(Math.random() * newPlayers.length);
      }

      let randomActionCardIndex = Math.floor(
        Math.random() * newPlayers[randomIndex].action_cards.length
      );

      newPlayers[randomIndex].action_cards.splice(randomActionCardIndex, 1);

      let idx = newPlayers[playerTurn].action_cards.findIndex(
        (p) => p.title == "STOCK DUMP"
      );

      //remove idx action card from index player
      newPlayers[playerTurn].action_cards.splice(idx, 1);

      newPlayers[playerTurn].moves -= 1;

      return newPlayers;
    });
  }

  function looseHalfShares(playerIndex: number, industry: string) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      (newPlayers[playerIndex] as any).portfolio[industry] -= Math.floor(
        (newPlayers[playerIndex] as any).portfolio[industry] / 2
      );

      return newPlayers;
    });
  }

  // decrease the portfolio of a particular industry of a random player player by half and transfer it to the current player
  function industrySteal(playerIndex: number, industry: string) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      (newPlayers[playerTurn] as any).portfolio[industry] += Math.round(
        (newPlayers[playerIndex] as any).portfolio[industry] / 2
      );
      (newPlayers[playerIndex] as any).portfolio[industry] -= Math.round(
        (newPlayers[playerIndex] as any).portfolio[industry] / 2
      );
      return newPlayers;
    });
  }

  // decrease the portfolio of a particular industry of a random player player by half and transfer it to the current player
  async function industryStealBot() {
    let randomIndex = Math.floor(Math.random() * players.length);
    while (randomIndex === playerTurn) {
      randomIndex = Math.floor(Math.random() * players.length);
    }
    const industries = [
      "finance",
      "real_estate",
      "consumer_goods",
      "healthcare",
      "commodities",
      "energy",
      "bonds",
      "technology",
    ];
    const randomIndustry =
      industries[Math.floor(Math.random() * industries.length)];

    if (randomIndex === 0) {
      const stockBlockBoolResult = await waitForStockBlockResult({
        title: "ROBINHOOD",
        body: "Steal half of a random industry from another player",
        image: RobinHood,
        action: async (index: number) => {
          const selectedActionPlayer = await waitForUserSelectInput();

          if (selectedActionPlayer) {
            industrySteal(selectedActionPlayer, randomIndustry);
            playActionCard(index);
            setSelectedActionPlayer(null);
          }
        },
        color: "#B75858",
        data: null,
      });

      if (stockBlockBoolResult === true) {
        setPlayers((oldPlayers) => {
          const newPlayers = [...oldPlayers];

          let idx = newPlayers[playerTurn].action_cards.findIndex(
            (p) => p.title == "ROBINHOOD"
          );

          //remove idx action card from index player
          newPlayers[playerTurn].action_cards.splice(idx, 1);
          newPlayers[playerTurn].moves -= 1;

          return newPlayers;
        });

        return;
      }
    }

    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      (newPlayers[playerTurn] as any).portfolio[randomIndustry] += Math.floor(
        (newPlayers[randomIndex] as any).portfolio[randomIndustry] / 2
      );
      (newPlayers[randomIndex] as any).portfolio[randomIndustry] -= Math.floor(
        (newPlayers[randomIndex] as any).portfolio[randomIndustry] / 2
      );

      let idx = newPlayers[playerTurn].action_cards.findIndex(
        (p) => p.title == "ROBINHOOD"
      );

      //remove idx action card from index player
      newPlayers[playerTurn].action_cards.splice(idx, 1);

      newPlayers[playerTurn].moves -= 1;

      return newPlayers;
    });
  }

  // reduce moves of a player with a particular index by a particular number
  function reducePlayerMoves(index: number, amount: number) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      newPlayers[index].moves -= amount;

      return newPlayers;
    });
  }

  // increase the portfolio of a particular industry which chosen by the player with the player turn by a given number
  function StockDividend(
    playerIndex: number,
    industry: string,
    shares: number
  ) {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      (newPlayers[playerIndex].portfolio as any)[industry] += shares;

      return newPlayers;
    });
  }

  // increase the portfolio of any one industry chosen at random of the player with the player turn by a given number
  function industryInvestment(shares: number) {
    const industries = [
      "finance",
      "real_estate",
      "consumer_goods",
      "healthcare",
      "commodities",
      "energy",
      "bonds",
      "technology",
    ];

    const randomIndex = Math.floor(Math.random() * industries.length);
    const randomIndustry = industries[randomIndex];

    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      (newPlayers[playerTurn].portfolio as any)[randomIndustry] += shares;
      return newPlayers;
    });
  }

  const waitForIndustrySelectInput = () => {
    return new Promise<string | null>((resolve) => {
      setSelectIndustryModalOpen(true);

      const intervalId = setInterval(() => {
        let promiseUserInput = selectedActionIndustry;
        let promiseSelectIndustryModalOpen = selectIndustryModalOpen;

        setSelectedActionIndustry((oldSelectedActionIndustry) => {
          promiseUserInput = oldSelectedActionIndustry;
          return oldSelectedActionIndustry;
        });

        setSelectIndustryModalOpen((oldSelectIndustryModalOpen) => {
          promiseSelectIndustryModalOpen = oldSelectIndustryModalOpen;
          return oldSelectIndustryModalOpen;
        });

        if (promiseUserInput) {
          clearInterval(intervalId);
          resolve(promiseUserInput);
        }

        if (!promiseSelectIndustryModalOpen) {
          clearInterval(intervalId);
          resolve(null);
        }
      }, 100);
    });
  };

  // a function which increases the cash of current player by 3000000 if the player has stocks in energy industry
  function energyInvestment() {
    let currentPlayer: PlayerModel | undefined;

    setPlayers((oldPlayers) => {
      currentPlayer = oldPlayers[playerTurn];
      return oldPlayers;
    });

    if (currentPlayer && (currentPlayer.portfolio as any).energy > 0) {
      increaseCurrentPlayerCash(3000000);
    }
  }

  const [actionDeck, setActionDeck] = useState<Array<ActionCardModel>>([
    {
      title: "FROZEN ASSET",
      body: "Skip (1) upcoming move of any player",
      image: FrozenAssest1,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
            playStockBlock(selectedActionPlayer);
          else reducePlayerMoves(selectedActionPlayer, 1);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "FROZEN ASSET",
      body: "Skip (1) upcoming move of any player",
      image: FrozenAssest1,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
            playStockBlock(selectedActionPlayer);
          else reducePlayerMoves(selectedActionPlayer, 1);

          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "FROZEN ASSETS",
      body: "Skip (2) upcoming move of any player",
      image: FrozenAssest2,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
            playStockBlock(selectedActionPlayer);
          else reducePlayerMoves(selectedActionPlayer, 2);

          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "FROZEN ASSETS",
      body: "Skip (2) upcoming move of any player",
      image: FrozenAssest2,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
            playStockBlock(selectedActionPlayer);
          else reducePlayerMoves(selectedActionPlayer, 2);

          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "INSIDER TRADING",
      body: "Pick any player to lose half their shares in any industry",
      image: InsiderPng,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          const selectedActionIndustry = await waitForIndustrySelectInput();

          if (selectedActionIndustry) {
            if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
              playStockBlock(selectedActionPlayer);
            else looseHalfShares(selectedActionPlayer, selectedActionIndustry);
            playActionCard(index);
            setSelectedActionIndustry(null);
          }

          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "INSIDER TRADING",
      body: "Pick any player to lose half their shares in any industry",
      image: InsiderPng,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          const selectedActionIndustry = await waitForIndustrySelectInput();

          if (selectedActionIndustry) {
            if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
              playStockBlock(selectedActionPlayer);
            else looseHalfShares(selectedActionPlayer, selectedActionIndustry);
            playActionCard(index);
            setSelectedActionIndustry(null);
          }

          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "ROBINHOOD",
      body: "Steal half an opponents shares in one industry (round up)",
      image: RobinHood,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          const selectedActionIndustry = await waitForIndustrySelectInput();

          if (selectedActionIndustry) {
            if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
              playStockBlock(selectedActionPlayer);
            else industrySteal(selectedActionPlayer, selectedActionIndustry);
            playActionCard(index);

            setSelectedActionIndustry(null);
          }
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "ROBINHOOD",
      body: "Steal half an opponents shares in one industry (round up)",
      image: RobinHood,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          const selectedActionIndustry = await waitForIndustrySelectInput();

          if (selectedActionIndustry) {
            if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
              playStockBlock(selectedActionPlayer);
            else industrySteal(selectedActionPlayer, selectedActionIndustry);
            playActionCard(index);

            setSelectedActionIndustry(null);
          }
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "ROBINHOOD",
      body: "Steal half an opponents shares in one industry (round up)",
      image: RobinHood,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          const selectedActionIndustry = await waitForIndustrySelectInput();

          if (selectedActionIndustry) {
            if (playerHasActionCard(selectedActionPlayer, "STOCK BLOCK"))
              playStockBlock(selectedActionPlayer);
            else industrySteal(selectedActionPlayer, selectedActionIndustry);
            playActionCard(index);

            setSelectedActionIndustry(null);
          }
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "SEE THE FUTURES",
      body: "Privately view the next event card",
      image: seetheFutureImage,
      action: async (index: number) => {
        await waitForEventCardToClose();
        playActionCard(index);
      },
      color: "#FFC302",
      data: null,
    },
    {
      title: "SEE THE FUTURES",
      body: "Privately view the next event card",
      image: seetheFutureImage,
      action: async (index: number) => {
        await waitForEventCardToClose();
        playActionCard(index);
      },
      color: "#FFC302",
      data: null,
    },
    {
      title: "SEE THE FUTURES",
      body: "Privately view the next event card",
      image: seetheFutureImage,
      action: async (index: number) => {
        await waitForEventCardToClose();
        playActionCard(index);
      },
      color: "#FFC302",
      data: null,
    },
    {
      title: "SEE THE FUTURES",
      body: "Privately view the next event card",
      image: seetheFutureImage,
      action: async (index: number) => {
        await waitForEventCardToClose();
        playActionCard(index);
      },
      color: "#FFC302",
      data: null,
    },
    {
      title: "SEE THE FUTURES",
      body: "Privately view the next event card",
      image: seetheFutureImage,
      action: async (index: number) => {
        await waitForEventCardToClose();
        playActionCard(index);
      },
      color: "#FFC302",
      data: null,
    },
    {
      title: "STOCK BLOCK",
      body: "Use anytime to stop the move or action of another player",
      image: stockBlockImage,
      action: (index: number) => {
        playActionCard(index);
      },
      color: "#587EB7",
      data: null,
    },
    {
      title: "STOCK BLOCK",
      body: "Use anytime to stop the move or action of another player",
      image: stockBlockImage,
      action: (index: number) => {
        playActionCard(index);
      },
      color: "#587EB7",
      data: null,
    },
    {
      title: "STOCK BLOCK",
      body: "Use anytime to stop the move or action of another player",
      image: stockBlockImage,
      action: (index: number) => {
        playActionCard(index);
      },
      color: "#587EB7",
      data: null,
    },
    {
      title: "STOCK BLOCK",
      body: "Use anytime to stop the move or action of another player",
      image: stockBlockImage,
      action: (index: number) => {
        playActionCard(index);
      },
      color: "#587EB7",
      data: null,
    },
    {
      title: "STOCK BLOCK",
      body: "Use anytime to stop the move or action of another player",
      image: stockBlockImage,
      action: (index: number) => {
        playActionCard(index);
      },
      color: "#587EB7",
      data: null,
    },
    {
      title: "STOCK BLOCK",
      body: "Use anytime to stop the move or action of another player",
      image: stockBlockImage,
      action: (index: number) => {
        playActionCard(index);
      },
      color: "#587EB7",
      data: null,
    },
    {
      title: "STOCK DUMP",
      body: "Randomly discard (1) Action Card of another player",
      image: StockDumb,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardDiscard(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "STOCK DUMP",
      body: "Randomly discard (1) Action Card of another player",
      image: StockDumb,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardDiscard(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "THIEF!",
      body: "Steal an action card at random from any player's hand",
      image: Thief,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardSteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "THIEF!",
      body: "Steal an action card at random from any player's hand",
      image: Thief,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardSteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "THIEF!",
      body: "Steal an action card at random from any player's hand",
      image: Thief,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardSteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "THIEF!",
      body: "Steal an action card at random from any player's hand",
      image: Thief,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardSteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "THIEF!",
      body: "Steal an action card at random from any player's hand",
      image: Thief,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          actionCardSteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "GREED IS GOOD",
      body: "Pickup 2 action cards",
      image: GreedIsGoodImage,
      action: (index: number) => {
        pickUpTwoActionCard();
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "GREED IS GOOD",
      body: "Pickup 2 action cards",
      image: GreedIsGoodImage,
      action: (index: number) => {
        pickUpTwoActionCard();
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "ASSET SALE",
      body: "Collect $3M in cash",
      image: AssetsSell,
      action: (index: number) => {
        increaseCurrentPlayerCash(3000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "HIGH PRODUCTIVITY",
      body: "Collect $5M cash",
      image: HignProductivity,
      action: (index: number) => {
        increaseCurrentPlayerCash(5000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "HIGH PRODUCTIVITY",
      body: "Collect $5M cash",
      image: HignProductivity,
      action: (index: number) => {
        increaseCurrentPlayerCash(5000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "NATION DONATION",
      body: "Steal $5m in cash from another player",
      image: NationDonation,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          moneySteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "NATION DONATION",
      body: "Steal $5m in cash from another player",
      image: NationDonation,
      action: async (index: number) => {
        const selectedActionPlayer = await waitForUserSelectInput();

        if (selectedActionPlayer) {
          moneySteal(selectedActionPlayer);
          playActionCard(index);
          setSelectedActionPlayer(null);
        }
      },
      color: "#B75858",
      data: null,
    },
    {
      title: "ENERGY TAX BENEFIT",
      body: "Collect $3M cash if invested in energy",
      image: EnergyTaxBenifit,
      action: (index: number) => {
        let currentPlayer: PlayerModel | undefined;

        setPlayers((oldPlayers) => {
          currentPlayer = oldPlayers[playerTurn];
          return oldPlayers;
        });
        if (currentPlayer && (currentPlayer.portfolio as any).energy > 0) {
          increaseCurrentPlayerCash(3000000);
          playActionCard(index);
        }
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "PERFORMANCE BONUS",
      body: "Collect $3M cash",
      image: PerformancePng,
      action: (index: number) => {
        increaseCurrentPlayerCash(3000000);
        playActionCard(index);
      },
      color: " #87B758",
      data: null,
    },
    {
      title: "SALARY INCREASE",
      body: "Collect $3M cash",
      image: SalaryIncrease,
      action: (index: number) => {
        increaseCurrentPlayerCash(3000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "SALARY INCREASE",
      body: "Collect $3M cash",
      image: SalaryIncrease,
      action: (index: number) => {
        increaseCurrentPlayerCash(3000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "TAX REFUND",
      body: "Collect $3M cash",
      image: TaxRefund,
      action: (index: number) => {
        increaseCurrentPlayerCash(3000000);
        playActionCard(index);
      },
      color: " #87B758",
      data: null,
    },
    {
      title: "STOCK DIVIDEND 2",
      body: "Gain 2 share of any industry",
      image: StockDiVidend2,
      action: async (index: number) => {
        setOpenStockDividendModal(true);
        const selectedActionIndustry = await waitForIndustrySelectInput();

        if (selectedActionIndustry) {
          StockDividend(playerTurn, selectedActionIndustry, 2);
          playActionCard(index);
          setSelectedActionIndustry(null);
        }
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "STOCK DIVIDEND 2",
      body: "Gain 2 share of any industry",
      image: StockDiVidend2,
      action: async (index: number) => {
        setOpenStockDividendModal(true);
        const selectedActionIndustry = await waitForIndustrySelectInput();

        if (selectedActionIndustry) {
          StockDividend(playerTurn, selectedActionIndustry, 2);
          playActionCard(index);
          setSelectedActionIndustry(null);
        }
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "STOCK DIVIDEND 1",
      body: "Gain 1 share of any industry",
      image: StockDiVidend1,
      action: async (index: number) => {
        setOpenStockDividendModal(true);
        const selectedActionIndustry = await waitForIndustrySelectInput();

        if (selectedActionIndustry) {
          StockDividend(playerTurn, selectedActionIndustry, 1);
          playActionCard(index);
          setSelectedActionIndustry(null);
        }
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "STOCK DIVIDEND 1",
      body: "Gain 1 share of any industry",
      image: StockDiVidend1,
      action: async (index: number) => {
        setOpenStockDividendModal(true);
        const selectedActionIndustry = await waitForIndustrySelectInput();

        if (selectedActionIndustry) {
          StockDividend(playerTurn, selectedActionIndustry, 1);
          playActionCard(index);
          setSelectedActionIndustry(null);
        }
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "STOCK DIVIDEND 1",
      body: "Gain 1 share of any industry",
      image: StockDiVidend1,
      action: async (index: number) => {
        setOpenStockDividendModal(true);
        const selectedActionIndustry = await waitForIndustrySelectInput();

        if (selectedActionIndustry) {
          StockDividend(playerTurn, selectedActionIndustry, 1);
          playActionCard(index);
          setSelectedActionIndustry(null);
        }
      },
      color: "#87B758",
      data: null,
    },

    {
      title: "GDP GROWTH",
      body: "All players collect $5M cash",
      image: GdpGrowthPng,
      action: (index: number) => {
        increaseAllPlayersCash(5000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "IPO SEASON",
      body: "Collect $4M cash",
      image: IpoSeasonPng,
      action: (index: number) => {
        increaseCurrentPlayerCash(4000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "TRUST FUND",
      body: "Collect $10M cash",
      image: TrustFundPng,
      action: (index: number) => {
        increaseCurrentPlayerCash(10000000);
        playActionCard(index);
      },
      color: " #87B758",
      data: null,
    },
    {
      title: "TRUST FUND",
      body: "Collect $10M cash",
      image: TrustFundPng,
      action: (index: number) => {
        increaseCurrentPlayerCash(10000000);
        playActionCard(index);
      },
      color: " #87B758",
      data: null,
    },
    {
      title: "YEAR END BONUSES",
      body: "All players collect $3M cash.",
      image: YearEndBonus,
      action: (index: number) => {
        increaseAllPlayersCash(3000000);
        playActionCard(index);
      },
      color: "#87B758",
      data: null,
    },
    {
      title: "STOCK DIVIDEND 3",
      body: "Gain 3 share of any industry",
      image: StockDiVidend3,
      action: async (index: number) => {
        setOpenStockDividendModal(true);
        const selectedActionIndustry = await waitForIndustrySelectInput();

        if (selectedActionIndustry) {
          StockDividend(playerTurn, selectedActionIndustry, 3);
          playActionCard(index);
          setSelectedActionIndustry(null);
        }
      },
      color: "#87B758",
      data: null,
    },
  ]);

  const [stockPrices, setStockPrices] =
    useState<StockModel>(initialStockPrices);

  //sell shares of a given industry and no of shares
  function sellSharesInAnIndustry(industry: string, noOfShares: number) {
    let newPlayers = [...players];
    newPlayers[playerTurn] = {
      ...newPlayers[playerTurn],
      cash:
        newPlayers[playerTurn].cash +
        noOfShares * (stockPrices as any)[industry].price,
      portfolio: {
        ...newPlayers[playerTurn].portfolio,
        [industry]:
          (newPlayers[playerTurn].portfolio as any)[industry] - noOfShares,
      },
    };
    setPlayers(newPlayers);
  }

  // which industry is sheares being bought by the bot
  const [botBuyingIndustry, setBotBuyingIndustry] = useState("");

  // which industry is sheares being sold by the bot
  const [botSellingIndustry, setBotSellingIndustry] = useState("");

  // how many shares are being bought by the bot
  const [botBuyingShares, setBotBuyingShares] = useState(0);
  // how many shares are being sold by the bot
  const [botSellingShares, setBotSellingShares] = useState(0);

  //buy random number of shares from a random industry
  function buyRandomShares() {
    let industries = Object.keys(stockPrices);
    let randomIndustry =
      industries[Math.floor(Math.random() * industries.length)];

    setPlayers((oldPlayers) => {
      let newPlayers = [...oldPlayers];
      let maxShares = Math.floor(
        newPlayers[playerTurn].cash / (stockPrices as any)[randomIndustry].price
      );
      let randomNoOfShares = Math.floor(Math.random() * (maxShares - 1) + 1);

      setBotBuyingIndustry(randomIndustry);
      setBotBuyingShares(randomNoOfShares);

      newPlayers[playerTurn].cash =
        newPlayers[playerTurn].cash -
        randomNoOfShares * (stockPrices as any)[randomIndustry].price;
      newPlayers[playerTurn].portfolio = {
        ...newPlayers[playerTurn].portfolio,
        [randomIndustry]:
          (newPlayers[playerTurn].portfolio as any)[randomIndustry] +
          randomNoOfShares,
      };

      newPlayers[playerTurn].moves -= 1;

      return newPlayers;
    });
  }

  //sell random number of shares from a random industry
  function sellRandomShares() {
    let industries = Object.keys(stockPrices);
    let randomIndustry =
      industries[Math.floor(Math.random() * industries.length)];

    setPlayers((oldPlayers) => {
      let newPlayers = [...oldPlayers];

      let maxShares = (newPlayers[playerTurn].portfolio as any)[randomIndustry];
      let randomNoOfShares = Math.floor(Math.random() * (maxShares - 1) + 1);
      // calulate random no of share as like it can not be smaller than 1
      // let randomNoOfShares = Math.floor(Math.random() * maxShares);

      setBotSellingIndustry(randomIndustry);
      setBotSellingShares(randomNoOfShares);

      newPlayers[playerTurn].cash =
        newPlayers[playerTurn].cash +
        randomNoOfShares * (stockPrices as any)[randomIndustry].price;
      newPlayers[playerTurn].portfolio = {
        ...newPlayers[playerTurn].portfolio,
        [randomIndustry]:
          (newPlayers[playerTurn].portfolio as any)[randomIndustry] -
          randomNoOfShares,
      };

      newPlayers[playerTurn].moves -= 1;

      return newPlayers;
    });
  }

  //check if any player other than player turn has cash or not
  function otherPlayersHaveCash() {
    for (let i = 0; i < players.length; i++) {
      if (i !== playerTurn && players[i].cash > 0) {
        return true;
      }
    }

    return false;
  }

  //check if any player other than player turn has action cards or not
  function otherPlayersHaveActionCards() {
    return players.some(
      (player, idx) => idx !== playerTurn && player.action_cards.length > 0
    );
  }

  //check if any player other than player turn has stocks or not
  function otherPlayersHaveStocks() {
    for (let i = 0; i < players.length; i++) {
      if (i !== playerTurn) {
        //convert player portfolio to array
        let playerPortfolio = Object.values(players[i].portfolio);
        //check if playerPortfolio has a value greater than 0
        if (playerPortfolio.some((value) => value > 0)) {
          return true;
        }
      }
    }
    return false;
  }

  function playACard(index: number, title: string) {
    setPlayers((prevPlayers) => {
      let newPlayers = [...prevPlayers];

      let idx = newPlayers[index].action_cards.findIndex(
        (p) => p.title == title
      );

      //remove idx action card from index player
      newPlayers[index].action_cards.splice(idx, 1);

      return newPlayers;
    });
  }

  function playStockBlock(index: number) {
    setPlayers((prevPlayers) => {
      let newPlayers = [...prevPlayers];

      let idx = newPlayers[index].action_cards.findIndex(
        (p) => p.title == "STOCK BLOCK"
      );

      //remove idx action card from index player
      newPlayers[index].action_cards.splice(idx, 1);

      return newPlayers;
    });
  }

  function playerHasActionCard(index: number, title: string) {
    let playerCards = players[index].action_cards;

    return playerCards.some((card) => card.title === title);
  }

  function playerHasActionCardBot(title: string) {
    let playerCards = players[playerTurn].action_cards;

    return playerCards.some((card) => card.title === title);
  }

  function playerHasOtherActionCardsToo(title: string) {
    let playerCards = players[playerTurn].action_cards;

    return playerCards.some((card) => card.title !== title);
  }

  function playerCanInvest() {
    //convert stock prices to array
    let stockPricesArray = Object.values(stockPrices);

    for (let i = 0; i < stockPricesArray.length; i++) {
      if (stockPricesArray[i].price <= players[playerTurn].cash) {
        return true;
      }
    }

    return false;
  }

  //does the player have any shares
  function playerHasShares() {
    let playerShares = players[playerTurn].portfolio;

    // convert player shares to array
    let playerSharesArray = Object.values(playerShares);

    // check if an entry in player shares array is greater than 0
    for (let i = 0; i < playerSharesArray.length; i++) {
      if (playerSharesArray[i] > 0) {
        return true;
      }
    }

    return false;
  }

  const [openTurnOverModal, setTurnOverModal] = useState(false);
  const [openRoundNoModal, setRoundNoModal] = useState(false);
  const [eventCardModal, setEndTurnEventCardModal] = useState(false);
  const [openTurnOverForPlayer, setTurnOverForPlayer] = useState(false);

  useEffect(() => {
    (async () => {
      if (roundNo < 7) {
        //wait until openTurnOverModel, openRoundNoModal, eventCardModal is set to false
        while (openTurnOverModal || openRoundNoModal || eventCardModal) {
          await new Promise((r) => setTimeout(r, 1000));
        }
        if (turnsCounter === 1) {
          if (roundNo === 1) {
            //write code for dealing 4 action cards to each player
            //Note - After an action card is dealt, it should be removed from deck
            let newActionDeck = [...shuffleDeck(actionDeck)];
            let newEventDeck = [...shuffleDeck(eventDeck)];

            setEventDeck(newEventDeck);

            setPlayers((oldPlayers) => {
              let newPlayers = [...oldPlayers];

              for (let i = 0; i < newPlayers.length; i++) {
                newPlayers[i] = {
                  ...newPlayers[i],

                  action_cards: [
                    ...newPlayers[i].action_cards,
                    ...newActionDeck.splice(newActionDeck.length - 4, 4),
                  ],
                };
              }

              setActionDeck(newActionDeck);

              return newPlayers;
            });
          }

          setDiceRoller(playerTurn);

          //  when its player turn, handleClick is called by clicking on dice otherwise it will be automatically called
          if (players[playerTurn].type === "bot") {
            await handleDiceClick(players);
          } else {
            if (roundNo !== 1 && roundNo === 5) {
              setTimeout(async () => {
                await drawActionCard(1);
              }, 500);
            }
          }
        }

        setTimeout(async () => {
          if (players[playerTurn].type === "bot" || turnsCounter !== 1)
            await drawActionCard(1);
        }, 500);

        if (players[playerTurn].type === "bot") {
          let moves = 3;
          while (moves > 0) {
            await timeout(2000);
            if (playerCanInvest()) {
              if (playerHasShares()) {
                if (playerHasOtherActionCardsToo("STOCK BLOCK")) {
                  if (
                    playerHasActionCardBot("NATION DONATION") &&
                    otherPlayersHaveCash()
                  ) {
                    await moneyStealBot();
                  } else {
                    if (
                      playerHasActionCardBot("ROBINHOOD") &&
                      otherPlayersHaveStocks()
                    ) {
                      await industryStealBot();
                    } else if (
                      playerHasActionCardBot("THIEF!") &&
                      otherPlayersHaveActionCards()
                    ) {
                      await actionCardStealBot();
                    } else if (
                      playerHasActionCardBot("STOCK DUMP") &&
                      otherPlayersHaveActionCards()
                    ) {
                      await actionCardDiscardBot();
                    } else {
                      const random = Math.floor(Math.random() * 2 + 1);

                      if (random === 1) {
                        buyRandomShares();
                      } else {
                        if (playerHasShares()) {
                          sellRandomShares();
                        }
                      }
                    }
                  }
                } else {
                  const random = Math.floor(Math.random() * 2 + 1);

                  if (random === 1) {
                    buyRandomShares();
                  } else {
                    if (playerHasShares()) {
                      sellRandomShares();
                    }
                  }
                }
              } else {
                buyRandomShares();
              }
            } else {
              if (playerHasShares()) {
                sellRandomShares();
              } else {
                console.log("Undefined Situation");
              }
            }
            moves--;
          }
        }
      }
    })();
  }, [playerTurn, openTurnOverModal, openRoundNoModal, eventCardModal]);

  const tickerItems = Object.values(stockPrices);

  function protectAction() {
    setPlayers((oldPlayers) => {
      const newPlayers = [...oldPlayers];
      newPlayers[playerTurn].noAction = true;

      return newPlayers;
    });
  }
  const [pickUpActionCard, setPickUpActionCard] = useState<null | boolean>(
    null
  );

  const waitForPlayerConfirmation = (newPopupActionCardDetails: any) => {
    return new Promise<boolean | null>((resolve) => {
      setPopupActionCardDetails(newPopupActionCardDetails);
      setActionCardPopup(true);

      const intervalId = setInterval(() => {
        let promiseSelectPlayerModalOpen = pickUpActionCard;

        setPickUpActionCard((oldpickUpActionCard) => {
          return oldpickUpActionCard;
        });

        setActionCardPopup((oldActionCardPopup) => {
          promiseSelectPlayerModalOpen = oldActionCardPopup;

          return oldActionCardPopup;
        });

        if (!promiseSelectPlayerModalOpen) {
          clearInterval(intervalId);
          resolve(false);
        }
      }, 100);
    });
  };
  async function drawActionCard(numberOfCards: number) {
    let newPlayers = [...players];
    let newActionDeck = [...actionDeck];

    setPlayers((oldPlayers) => {
      newPlayers = [...oldPlayers];
      return oldPlayers;
    });

    setActionDeck((oldActionDeck) => {
      newActionDeck = [...oldActionDeck];
      return oldActionDeck;
    });

    if (newPlayers[playerTurn].type !== "bot") {
      await waitForPlayerConfirmation(newActionDeck[newActionDeck.length - 1]);
    }

    setActionDeck((oldActionDeck) => {
      const newActionDeck = [...oldActionDeck];

      newPlayers[playerTurn].action_cards = [
        ...newPlayers[playerTurn].action_cards,
        ...newActionDeck.splice(
          newActionDeck.length - numberOfCards,
          numberOfCards
        ),
      ];

      return newActionDeck;
    });
  }

  const calculateNetWorth = (playerInfo: PlayerModel) => {
    const playerNetWorth =
      playerInfo.cash +
      playerInfo.portfolio.bonds * stockPrices.bonds.price +
      playerInfo.portfolio.commodities * stockPrices.commodities.price +
      playerInfo.portfolio.consumer_goods * stockPrices.consumer_goods.price +
      playerInfo.portfolio.finance * stockPrices.finance.price +
      playerInfo.portfolio.energy * stockPrices.energy.price +
      playerInfo.portfolio.healthcare * stockPrices.healthcare.price +
      playerInfo.portfolio.real_estate * stockPrices.real_estate.price +
      playerInfo.portfolio.technology * stockPrices.technology.price;
    return playerNetWorth;
  };

  function handleClick(event: any) {
    setButtonText(event.target.textContent);
  }

  // change this function so that it uses the player with player turn
  function buyShares(shares: number) {
    let newPlayers = [...players];

    newPlayers[playerTurn] = {
      ...newPlayers[playerTurn],
      cash:
        newPlayers[playerTurn].cash -
        shares * (stockPrices as any)[selectedShare].price,
      portfolio: {
        ...newPlayers[playerTurn].portfolio,
        [selectedShare]:
          (newPlayers[playerTurn].portfolio as any)[selectedShare] + shares,
      },
      moves: newPlayers[playerTurn].moves - 1,
    };

    setPlayers(newPlayers);
  }

  // change this function so that it uses the player with player turn
  function sellShares(shares: number) {
    let newPlayers = [...players];

    newPlayers[playerTurn] = {
      ...newPlayers[playerTurn],
      cash:
        newPlayers[playerTurn].cash +
        shares * (stockPrices as any)[selectedShare].price,
      portfolio: {
        ...newPlayers[playerTurn].portfolio,
        [selectedShare]:
          (newPlayers[playerTurn].portfolio as any)[selectedShare] - shares,
      },
      moves: newPlayers[playerTurn].moves - 1,
    };

    setPlayers(newPlayers);
  }

  useEffect(() => {
    if (players[playerTurn].moves === 0) {
      if (players[playerTurn].type !== "bot") {
        setTurnOverForPlayer(true);
      }
      let newPlayers = [...players];
      newPlayers[playerTurn] = { ...newPlayers[playerTurn], moves: 3 };
      setPlayers(newPlayers);

      if (turnsCounter === 4) {
        setPlayerTurn((diceRoller + 1) % players.length);

        if (roundNo < 7) {
          handleModalTurnOver();
        }

        setPlayersHistory((oldPlayersHistory) => {
          const newPlayersHistory = [...oldPlayersHistory];
          newPlayersHistory.push(
            players.map((player) => {
              return { ...player, netWorth: calculateNetWorth(player) };
            })
          );
          return newPlayersHistory;
        });

        Mixpanel.track("Round " + roundNo + " Finished");

        setRoundNo(roundNo + 1);

        turnsCounter = 1;
      } else {
        setPlayerTurn((playerTurn + 1) % players.length);
        turnsCounter++;
      }

      setBotBuyingIndustry("");
      setBotSellingIndustry("");
      setBotBuyingShares(0);
      setBotSellingShares(0);
    }
  }, [players[playerTurn].moves]);

  function pickUpTwoActionCard() {
    setActionDeck((oldActionDeck) => {
      let newActionDeck = [...oldActionDeck];

      setPlayers((oldPlayers) => {
        const newPlayers = [...oldPlayers];

        newPlayers[playerTurn].action_cards.push(
          ...newActionDeck.splice(newActionDeck.length - 2, 2)
        );

        return newPlayers;
      });

      return newActionDeck;
    });
  }

  function playEventCard() {
    setEventCardDetails(eventDeck[eventDeck.length - 1]);
    setTickerEventCard(eventDeck[eventDeck.length - 1]);

    let newStockPrices = { ...stockPrices };
    newStockPrices = {
      technology: {
        name: "Technology",
        price: Math.max(
          stockPrices.technology.price +
            eventDeck[eventDeck.length - 1].stock_changes.technology * 1000000 <
            1000000
            ? 1000000
            : stockPrices.technology.price +
                eventDeck[eventDeck.length - 1].stock_changes.technology *
                  1000000,
          0
        ),
      },
      real_estate: {
        name: "Real Estate",
        price: Math.max(
          stockPrices.real_estate.price +
            eventDeck[eventDeck.length - 1].stock_changes.real_estate *
              1000000 <
            1000000
            ? 1000000
            : stockPrices.real_estate.price +
                eventDeck[eventDeck.length - 1].stock_changes.real_estate *
                  1000000,
          0
        ),
      },
      healthcare: {
        name: "Healthcare",
        price: Math.max(
          stockPrices.healthcare.price +
            eventDeck[eventDeck.length - 1].stock_changes.healthcare * 1000000 <
            1000000
            ? 1000000
            : stockPrices.healthcare.price +
                eventDeck[eventDeck.length - 1].stock_changes.healthcare *
                  1000000,
          0
        ),
      },
      finance: {
        name: "Finance",
        price: Math.max(
          stockPrices.finance.price +
            eventDeck[eventDeck.length - 1].stock_changes.finance * 1000000 <
            1000000
            ? 1000000
            : stockPrices.finance.price +
                eventDeck[eventDeck.length - 1].stock_changes.finance * 1000000,
          0
        ),
      },
      energy: {
        name: "Energy",
        price: Math.max(
          stockPrices.energy.price +
            eventDeck[eventDeck.length - 1].stock_changes.energy * 1000000 <
            1000000
            ? 1000000
            : stockPrices.energy.price +
                eventDeck[eventDeck.length - 1].stock_changes.energy * 1000000,
          0
        ),
      },
      consumer_goods: {
        name: "Consumer Goods",
        price: Math.max(
          stockPrices.consumer_goods.price +
            eventDeck[eventDeck.length - 1].stock_changes.consumer_goods *
              1000000 <
            1000000
            ? 1000000
            : stockPrices.consumer_goods.price +
                eventDeck[eventDeck.length - 1].stock_changes.consumer_goods *
                  1000000,
          0
        ),
      },
      commodities: {
        name: "Commodities",
        price: Math.max(
          stockPrices.commodities.price +
            eventDeck[eventDeck.length - 1].stock_changes.commodities *
              1000000 <
            1000000
            ? 1000000
            : stockPrices.commodities.price +
                eventDeck[eventDeck.length - 1].stock_changes.commodities *
                  1000000,
          0
        ),
      },
      bonds: {
        name: "Bonds",
        price: Math.max(
          stockPrices.bonds.price +
            eventDeck[eventDeck.length - 1].stock_changes.bonds * 1000000 <
            1000000
            ? 1000000
            : stockPrices.bonds.price +
                eventDeck[eventDeck.length - 1].stock_changes.bonds * 1000000,
          0
        ),
      },
    };
    setStockPrices(newStockPrices);

    let newEventDeck = [...eventDeck];
    newEventDeck.splice(newEventDeck.length - 1, 1);
    setEventDeck(newEventDeck);
  }

  const handleModalTurnOver = () => {
    setEventCardDetails(eventDeck[eventDeck.length - 1]);
    setEndTurnEventCardModal(true);
  };

  const gridArray = ["", "", "", "portfolio", "marquee"];
  const [highLightGrid, setHighLightGrid] = useState("");
  function highLightGridFunction(id: string) {
    setHighLightGrid(id);
  }

  const user = accountService.userValue;

  const handleFitstTime = (user: any) => {
    accountService.changeFirstTime({ user });
  };

  const [openRuleBookModal, setRuleBookModal] = useState(false);
  const [showStartTooltip, setShowStartTooltip] = useState(true);

  const isMobile = useMediaQuery("(max-width:1000px)");

  const [isLandscape, setIsLandscape] = useState(
    window.orientation === 90 || window.orientation === -90
  );

  useEffect(() => {
    Mixpanel.track("Game Loaded");

    const handleOrientationChange = () => {
      setIsLandscape(window.orientation === 90 || window.orientation === -90);
    };

    window.onbeforeunload = (event) => {
      Mixpanel.track("Session Ended");
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  // open ThirdRoundEmailModal after 3rd event card is played

  const [openThirdRoundEmailModal, setOpenThirdRoundEmailModal] =
    useState(false);
  let subscribedUser = Cookies.get("subscribedUser");
  useEffect(() => {
    if (roundNo === 4 && !subscribedUser) {
      setTimeout(() => {
        setOpenThirdRoundEmailModal(true);
      }, 1000);
    }
  }, [roundNo]);
  const [openDiscountModal, setOpenDiscountModal] = useState(false);

  useEffect(() => {
    if (openResultModal && !subscribedUser) {
      setTimeout(() => {
        setOpenDiscountModal(true);
      }, 500);
    }
  }, [openResultModal]);
  const history = useHistory();

  // set cookies firstTime true
  useEffect(() => {
    const WebsiteVisited = Cookies.get("WebsiteVisited");
    const UserSubscribed = Cookies.get("UserSubscribed");
    if (!WebsiteVisited) {
      Cookies.set("WebsiteVisited", "true");
      setOpenWalkthroughModal(true);
    } else if (!UserSubscribed) {
      setOpenDiscountModal(true);
    }
  }, []);

  return (
    <Grid container direction="column">
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(5px)",
          zIndex: 9999,
          display: !isLandscape && isMobile ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Typography variant="h3" style={{ color: "#fff", textAlign: "center" }}>
          Switch to landscape mode to continue
        </Typography>
      </div>
      <Grid
        id={gridArray[0]}
        item
        sx={{
          background: "#2C2C2C",
          zIndex: `${
            highLightGrid === "marquee" && openWalkthroughModal ? 9999 : 0
          }`,
          width: "100vw",
        }}
      >
        <Marquee
          pauseOnHover={true}
          style={{
            background: "#2C2C2C",
            height: "8vh",
          }}
          speed={50}
          gradient={false}
        >
          {tickerItems.map((item, index) => (
            <Typography
              sx={{
                marginLeft: "70px",
                paddingTop: "1.5vh",
                fontFamily: "Minecraft",
                fontStyle: "normal",
                color: "white",
                fontSize: { xs: 10, lg: 16 },
              }}
              key={item.name}
            >
              {item.name}
              <span style={{ paddingLeft: "10px" }}>
                ${item.price / 1000000} <span>M</span>
              </span>

              {tickerEventCard &&
                tickerEventCard?.stock_changes[
                  Object.keys(stockPrices)[index]
                ] !== null &&
                tickerEventCard?.stock_changes[
                  Object.keys(stockPrices)[index]
                ] !== 0 && (
                  <span style={{ marginLeft: "10px" }}>
                    <Box
                      component="img"
                      src={
                        tickerEventCard?.stock_changes[
                          Object.keys(stockPrices)[index]
                        ] > 0
                          ? UpPng
                          : DownPng
                      }
                      alt=""
                      sx={{
                        width: { xs: "7px", lg: "14px" },
                        height: { xs: "7px", lg: "14px" },
                        paddingTop: { xs: "2.5px", lg: "5px" },
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        width: "20px",
                        height: "24px",
                        padding: "7px",
                        background: `${
                          tickerEventCard?.stock_changes[
                            Object.keys(stockPrices)[index]
                          ] > 0
                            ? "#49E400"
                            : "#E40000"
                        }`,
                        borderRadius: "4px",
                      }}
                    >
                      {
                        tickerEventCard?.stock_changes[
                          Object.keys(stockPrices)[index]
                        ]
                      }
                    </span>
                  </span>
                )}
            </Typography>
          ))}
        </Marquee>
      </Grid>
      <Grid item container sx={{ height: "92vh", width: "100vw" }}>
        <Grid
          item
          sx={{
            background: "#000814",
            height: "100%",
          }}
          sm={1}
          md={1}
        >
          {players && calculateNetWorth && (
            <LeaderBoard
              players={players}
              calculateNetWorth={calculateNetWorth}
              setOpenProfile={setOpenProfile}
              highLightGrid={highLightGrid}
              openWalkthroughModal={openWalkthroughModal}
            />
          )}
        </Grid>
        <Grid
          id={gridArray[1]}
          item
          sx={{
            background: "#F2F5F9",
            height: "100%",
          }}
          direction="column"
          justifyContent="space-between"
          sm={5}
          md={4}
        >
          <Grid
            item
            sx={{
              height: "90%",
              zIndex: `${
                highLightGrid === "portfolio" && openWalkthroughModal ? 9999 : 0
              }`,
              position: `${
                highLightGrid === "portfolio" && openWalkthroughModal
                  ? "relative"
                  : ""
              }`,
              background: `${
                highLightGrid === "portfolio" && openWalkthroughModal
                  ? "#F2F5F9"
                  : ""
              }`,
            }}
          >
            <PortFolio
              players={players}
              calculateNetWorth={calculateNetWorth}
              playerInfo={players[0]}
              stockPrices={stockPrices}
              higLightGrid={highLightGrid}
              openWalkthroughModal={openWalkthroughModal}
            />
          </Grid>
          <Grid
            item
            sx={{
              height: "10%",
              zIndex: `${
                highLightGrid === "buySell" && openWalkthroughModal ? 2999 : 0
              }`,
              position: `${
                highLightGrid === "buySell" && openWalkthroughModal
                  ? "relative"
                  : ""
              }`,
              border: `${
                highLightGrid === "buySell" && openWalkthroughModal
                  ? "3px solid skyblue"
                  : ""
              }`,
              // opacity: 3,
            }}
          >
            <BuySellEndTurn
              players={players}
              playerTurn={playerTurn}
              setSelectedNoOfShares={setSelectedNoOfShares}
              setBuySellModalOpen={setBuySellModalOpen}
              handleClick={handleClick}
              setPlayers={setPlayers}
              roundNo={roundNo}
              eventCardDetails={eventCardDetails}
              diceClick={diceClick}
              setTurnOverForPlayer={setTurnOverForPlayer}
              highLightGrid={highLightGrid}
              PopupActionCardDetails={PopupActionCardDetails}
              openResultModal={openResultModal}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          sx={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #001D3D",
            height: "100%",
            position: "relative",
          }}
          sm={6}
          md={7}
        >
          <Grid
            item
            sx={{
              top: { xs: -10, lg: 0 },
              right: 20,
              cursor: "pointer",
              display: "flex",
              position: "absolute",
            }}
          >
            <p
              style={{
                padding: "0.3vw",
                background: "#F2F5F9",
                boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "3px",
              }}
              onClick={() => {
                setOpenProfile(true);
              }}
            >
              <Box
                component="img"
                src={scoreBoardPng}
                sx={{ width: { xs: 12, lg: 23 }, height: { xs: 12, lg: 23 } }}
                alt=""
              />
            </p>
            {user?.id && (
              <p
                style={{
                  padding: "0.3vw",
                  background: "#F2F5F9",
                  boxShadow: "0px 4px 10px rgba(53, 53, 53, 0.1)",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "5px",
                }}
                onClick={() => {
                  accountService.logout();

                  // window.location.reload();
                  history.push("/");
                }}
              >
                <Box
                  component="img"
                  src={logoutICon}
                  sx={{ width: { xs: 12, lg: 23 }, height: { xs: 12, lg: 23 } }}
                  alt=""
                />
              </p>
            )}
          </Grid>

          <Players
            playerTurn={playerTurn}
            setOpenProfile={setOpenProfile}
            diceRoller={diceRoller}
            face={face}
            isRolling={isRolling}
            players={players}
            stockPrices={stockPrices}
            botBuyingIndustry={botBuyingIndustry}
            botBuyingShares={botBuyingShares}
            setBotBuyingIndustry={setBotBuyingIndustry}
            setBotSellingIndustry={setBotSellingIndustry}
            botSellingIndustry={botSellingIndustry}
            botSellingShares={botSellingShares}
            highLightGrid={highLightGrid}
            openWalkthroughModal={openWalkthroughModal}
            openResultModal={openResultModal}
            // /// today adeded
            openTurnOverModal={openTurnOverModal}
            openRoundNoModal={openRoundNoModal}
            endTurnEventCardModal={eventCardModal}
            disableLoading={
              eventCardModal ||
              openTurnOverModal ||
              openRoundNoModal ||
              openTurnOverForPlayer ||
              actionCardPopup
            }
          />
          <EventCardActionCard
            highLightGrid={highLightGrid}
            roundNo={roundNo}
            openWalkthroughModal={openWalkthroughModal}
          />
          <Grid
            item
            container
            justifyContent="center"
            sx={{
              zIndex: `${
                highLightGrid === "dice" && openWalkthroughModal ? 9999 : ""
              }`,
            }}
          >
            <div
              style={{
                position: "relative",
                boxShadow: `${
                  showStartTooltip
                    ? "0 0 0 max(100vh, 100vw) rgba(0, 0, 0, .3)"
                    : ""
                }`,
                height: 0,
                marginBottom: 20,
              }}
            >
              {diceRoller === 0 ? (
                <Dice
                  onClick={async () => {
                    setShowStartTooltip(false);
                    if (
                      players[playerTurn].type === "player" &&
                      !diceClick &&
                      roundNo === 1
                    ) {
                      Mixpanel.track("Dice Roll");

                      setDiceClick(true);
                      await handleDiceClick(players);

                      setOpenWalkthroughModal(false);
                      handleFitstTime(user);
                    }
                  }}
                  face={face}
                  isRolling={isRolling}
                />
              ) : (
                <p>&nbsp;</p>
              )}
              {showStartTooltip && !openWalkthroughModal && (
                <img
                  src={require("../assets/ToolTips/Tooltip (10).png")}
                  style={{
                    position: "absolute",
                    width: "10vw",
                    right: "2vw",
                    top: "-2vw",
                  }}
                  alt=""
                />
              )}
            </div>
          </Grid>
          <Grid
            item
            sx={{
              marginBottom: { lg: "-100px" },
              backgroundColor: "rgba(255, 255, 255, 0.1)",
              border: "1px dashed #FFFFFF",
              borderColor: "rgba(255, 255, 255, 0.1)",
              borderRadius: "10px",
              width: { xs: 250, lg: 500 },
              height: { xs: 125, lg: 250 },
              padding: { xs: "10px", lg: "20px" },
              paddingX: "50px",
              marginX: "auto",
              zIndex: `${
                highLightGrid === "playerDeck" && openWalkthroughModal
                  ? 9999
                  : ""
              }`,
            }}
          >
            <PlayerDeck
              actionCards={players[0].action_cards}
              playable={players[0].moves > 0 && playerTurn === 0 && diceClick}
              playerTurn={playerTurn}
            />
          </Grid>
          <Grid
            item
            sx={{
              bottom: 10,
              right: 20,
              cursor: "pointer",
              display: "flex",
              position: "absolute",
            }}
          >
            <img
              src={infoICon}
              alt=""
              style={{ height: "24px", width: "24px", marginRight: "25%" }}
              onClick={() => {
                Mixpanel.track("Rule Book Opened");
                setRuleBookModal(true);
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <BasicModal
        open={buySellModalOpen}
        setOpen={setBuySellModalOpen}
        setQtyModalOpen={setQtyModalOpen}
        modaltype={buttonText}
        setSelectedShare={setSelectedShare}
        player={players[playerTurn]}
        shares={stockPrices}
        // actionCardDetails={actionCardDetails}
      />
      <QtyModal
        open={qtyModelOpen}
        setOpen={setQtyModalOpen}
        setConfirmationModalOpen={setConfirmationModalOpen}
        modaltype={buttonText}
        share={(stockPrices as any)[selectedShare]}
        cash={players[playerTurn].cash}
        playerShares={(players[playerTurn].portfolio as any)[selectedShare]}
        setSelectedNoOfShares={setSelectedNoOfShares}
        setBuySellModalOpen={setBuySellModalOpen}
        selectedNoOfShares={selectedNoOfShares}
      />
      <ConfirmationModal
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
        modaltype={buttonText}
        playerShares={(players[playerTurn].portfolio as any)[selectedShare]}
        share={(stockPrices as any)[selectedShare]}
        selectedNoOfShares={selectedNoOfShares}
        buyShares={buyShares}
        sellShares={sellShares}
        setQtyModalOpen={setQtyModalOpen}
        cash={players[playerTurn].cash}
      />
      <PopUpActionCardModal
        setPlayStockBlockBool={setPlayStockBlockBool}
        open={actionCardPopup}
        setOpen={setActionCardPopup}
        PopupActionCardDetails={PopupActionCardDetails}
        player={players[playerTurn]}
        playStockBlock={playStockBlock}
        playerHasActionCard={playerHasActionCard}
        setPickUpActionCard={setPickUpActionCard}
      />
      <EventCardModal
        eventCardDetails={eventCardDetails}
        open={openEventCardModal}
        setOpen={setEventCardModal}
      />
      <ProfileModal
        open={openProfile}
        setOpen={setOpenProfile}
        playerInfo1={players[1]}
        playerInfo2={players[2]}
        playerInfo3={players[3]}
        playersHistory={playersHistory}
        calculateNetWorth={calculateNetWorth}
        stockPrices={stockPrices}
        higLightGrid={highLightGrid}
        openWalkthroughModal={openWalkthroughModal}
      />
      {openResultModal && (
        <ResultModal
          players={players}
          calculateNetWorth={calculateNetWorth}
          setStartTooltip={setShowStartTooltip}
        />
      )}
      <EndTurnModal
        openModal1={openTurnOverModal}
        setOpenModal1={setTurnOverModal}
        openModal2={openRoundNoModal}
        setOpenModal2={setRoundNoModal}
        openModal3={eventCardModal}
        setOpenModal3={setEndTurnEventCardModal}
        roundNo={roundNo}
        eventCardDetails={eventCardDetails}
        setOpenResultModal={setOpenResultModal}
        players={players}
        playerTurn={playerTurn}
        openTurnOverForPlayer={openTurnOverForPlayer}
        setTurnOverForPlayer={setTurnOverForPlayer}
        playEventCard={playEventCard}
        disableEnter={openThirdRoundEmailModal || openDiscountModal}
      />

      <PlayerSelectionModal
        players={players}
        selectPlayerModelOpen={selectPlayerModalOpen}
        setSelectPlayerModelOpen={setSelectPlayerModalOpen}
        setSelectedActionPlayer={setSelectedActionPlayer}
      />
      {selectedActionPlayer !== null && (
        <ActionIndustrySelectionModal
          open={selectIndustryModalOpen}
          setOpen={setSelectIndustryModalOpen}
          setSelectedShare={setSelectedActionIndustry}
          player={selectedActionPlayer ? players[selectedActionPlayer] : null}
          shares={stockPrices}
        />
      )}
      <StockDividendModal
        open={openStockDividendModal}
        setOpen={setOpenStockDividendModal}
        setStockDividendIndustry={setSelectedActionIndustry}
        shares={stockPrices}
      />
      {openTurnOverForPlayer && (
        <TurnOverModalForPlayer
          open={openTurnOverForPlayer}
          setTurnOverForPlayer={setTurnOverForPlayer}
        />
      )}
      <WalkThroughModal
        highLightGridFunction={highLightGridFunction}
        setHighLightGrid={setHighLightGrid}
        openWalkthroughModal={openWalkthroughModal}
        setOpenWalkthroughModal={setOpenWalkthroughModal}
        user={accountService.userValue}
        handleFitstTime={handleFitstTime}
        highLightGrid={highLightGrid}
      />
      <RuleBookModal
        openRuleBookModal={openRuleBookModal}
        setRuleBookModal={setRuleBookModal}
        setOpenWalkthroughModal={setOpenWalkthroughModal}
      />

      <ThirdRoundEmailModal
        open={openThirdRoundEmailModal}
        setOpen={setOpenThirdRoundEmailModal}
      />

      <EndRoundDisccountModal
        open={openDiscountModal}
        setOpen={setOpenDiscountModal}
      />
    </Grid>
  );
};

export default HomePage;
