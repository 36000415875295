import {
  Box,
  Button,
  IconButton,
  Input,
  Modal,
  Paper,
  Typography,
} from "@mui/material";

import { MdArrowBack, MdClose } from "react-icons/md";

export default function QtyModal({
  open,
  setOpen,
  setConfirmationModalOpen,
  playerShares,
  modaltype,
  share,
  cash,
  setSelectedNoOfShares,
  setBuySellModalOpen,
  selectedNoOfShares,
}: any) {
  function previousModal() {
    setOpen(false);
    setBuySellModalOpen(true);
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      disableAutoFocus={true}
    >
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          width: { xs: 250, lg: 500 },
          borderRadius: "md",
          p: { sm: 1, lg: 5 },
          boxShadow: "lg",
          background: "#F2F5F9",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            color: "gray",
          }}
        >
          <MdClose size={"3vh"} />
        </IconButton>
        <IconButton
          onClick={previousModal}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            color: "gray",
          }}
        >
          <MdArrowBack size={"3vh"} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={require("../../assets/IndustryIcons/" +
              share.name.toUpperCase() +
              ".png")}
            sx={{
              height: { sm: "12px", lg: "24px" },
              width: { sm: "12px", lg: "24px" },
            }}
            alt=""
          />
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { sm: "10px", lg: "20px" },
              marginTop: "1vh",
            }}
          >
            {modaltype === "BUY" ? "Buy" : "Sell"} - {share.name}
          </Typography>

          {modaltype === "BUY" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "8px", lg: "16px" },
                marginTop: "1vh",
              }}
            >
              Cash Balance : <span style={{ paddingRight: "3px" }}>$</span>
              {cash / 1000000}M{" "}
            </Typography>
          )}

          {modaltype === "BUY" && (
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "8px", lg: "16px" },
                marginTop: "1vh",
              }}
            >
              Price per share: $ {share.price / 1000000}M
            </Typography>
          )}

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "8px", lg: "16px" },
              marginTop: "1vh",
            }}
          >
            How many shares do you want to{" "}
            {modaltype === "BUY" ? "buy" : "sell"}?
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Input
              value={selectedNoOfShares}
              inputProps={{ style: { textAlign: "center" } }}
              sx={{
                width: { xs: 30, lg: 57 },
                height: { xs: 20, lg: 36 },
                border: "2px solid #001D3D",
                borderRadius: { xs: "5px", lg: "10px" },
                marginTop: "9px",
                fontSize: { xs: 10, lg: 16 },
              }}
              type="number"
              slotProps={{
                input: {
                  min: 1,
                  max:
                    modaltype === "BUY"
                      ? Math.floor(cash / share.price)
                      : playerShares,
                  step: 1,
                },
              }}
              onChange={(e) => {
                setSelectedNoOfShares(parseInt(e.target.value));
              }}
            />
            <Typography
              sx={{
                marginTop: 1,
                width: { sm: "30px", lg: "46px" },
                height: { sm: "12px", lg: "24px" },
                background: " #87B758",
                border: "2px solid #264B00",
                borderRadius: "5px",
                color: " #2C2C2C",
                fontWeight: 400,
                fontSize: { xs: "8px", lg: "12px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "10px",
                cursor: "pointer",
              }}
              onClick={() =>
                setSelectedNoOfShares(
                  modaltype === "BUY"
                    ? Math.floor(cash / share.price)
                    : playerShares
                )
              }
            >
              MAX
            </Typography>
          </div>

          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "8px", lg: "16px" },
              marginTop: "1vh",
            }}
          >
            {modaltype === "SELL" && `Amount of shares owned: ${playerShares}`}
          </Typography>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { sm: "35px", lg: "77px" },
              height: { sm: "20px", lg: "37px" },
              color: "#2C2C2C",
              background: "#FFC700",
              border: "2px solid #9C7A00",
              borderRadius: "5px",
              cursor: "pointer",
              marginTop: "1vh",
              fontSize: { xs: "9px", lg: "16px" },
            }}
            disabled={
              modaltype === "BUY"
                ? cash / 1000000 >= (share.price * selectedNoOfShares) / 1000000
                  ? false
                  : true
                : playerShares === 0 ||
                  parseInt(playerShares) < parseInt(selectedNoOfShares)
                ? true
                : false
            }
            onClick={() => {
              setOpen(false);
              setConfirmationModalOpen(true);
            }}
          >
            NEXT
          </Button>
          <Typography
            sx={{
              color: "red",
              fontWeight: 500,
              fontSize: { xs: "8px", lg: "16px" },
              marginTop: "1vh",
            }}
          >
            {modaltype === "BUY"
              ? cash / 1000000 >= (share.price * selectedNoOfShares) / 1000000
                ? ""
                : "Not Enough Cash"
              : playerShares === 0 ||
                parseInt(playerShares) < parseInt(selectedNoOfShares)
              ? "Not Enough Shares"
              : ""}
          </Typography>
        </Box>
      </Paper>
    </Modal>
  );
}
