import { Grid } from "@mui/material";
import React from "react";
import avatar1 from "../assets/Untitled-1 (1) 1 (1).png";
import avatar2 from "../assets/swam 1 (1).png";
import avatar3 from "../assets/Untitled-1 1 (1).png";
import cardIcon from "../assets/Frame 155.png";
import { PlayerModel } from "../shared/models/PlayerModel";
import icon from "../assets/Icon.png";
import { StockModel } from "../shared/models/StockModel";

import Player from "./Player";
const Players = ({
  playerTurn,
  diceRoller,
  isRolling,
  face,
  players,
  stockPrices,
  botBuyingIndustry,
  botBuyingShares,
  botSellingIndustry,
  botSellingShares,
  highLightGrid,
  setOpenProfile,
  openWalkthroughModal,
  openResultModal,
  openTurnOverModal,
  openRoundNoModal,
  endTurnEventCardModal,
  disableLoading,
}: {
  playerTurn: number;
  setOpenProfile: React.Dispatch<React.SetStateAction<boolean>>;
  isRolling: boolean;
  face: number;
  players: Array<PlayerModel>;
  diceRoller: number;
  stockPrices: StockModel;
  botBuyingIndustry: string;
  botBuyingShares: number;
  botSellingIndustry: string;
  botSellingShares: number;
  setBotSellingIndustry: React.Dispatch<React.SetStateAction<string>>;
  setBotBuyingIndustry: React.Dispatch<React.SetStateAction<string>>;
  highLightGrid: string;
  openWalkthroughModal: boolean;
  openResultModal: boolean;
  disableLoading: boolean;

  openTurnOverModal: boolean;
  openRoundNoModal: boolean;
  endTurnEventCardModal: boolean;
}) => {
  return (
    <Grid
      item
      container
      justifyContent="center"
      sx={{
        zIndex: `${
          highLightGrid === "players" && openWalkthroughModal ? 2000 : ""
        }`,
        marginTop: "2vh",
      }}
    >
      <Grid item container xs={2} sx={{ marginX: 2.5 }} justifyContent="center">
        <Player
          openProfile={() => setOpenProfile(true)}
          color="#87B758"
          avatar={avatar1}
          playerIndex={1}
          diceRoller={diceRoller}
          face={face}
          isRolling={isRolling}
          players={players}
          playerTurn={playerTurn}
          botBuyingIndustry={botBuyingIndustry}
          botBuyingShares={botBuyingShares}
          botSellingIndustry={botSellingIndustry}
          botSellingShares={botSellingShares}
          icon={icon}
          cardIcon={cardIcon}
          openResultModal={openResultModal}
          disableLoading={disableLoading}
        />
      </Grid>
      <Grid item container xs={2} sx={{ marginX: 2.5 }} justifyContent="center">
        <Player
          openProfile={() => setOpenProfile(true)}
          color="#FFC300"
          avatar={avatar2}
          playerIndex={2}
          diceRoller={diceRoller}
          face={face}
          isRolling={isRolling}
          players={players}
          playerTurn={playerTurn}
          botBuyingIndustry={botBuyingIndustry}
          botBuyingShares={botBuyingShares}
          botSellingIndustry={botSellingIndustry}
          botSellingShares={botSellingShares}
          icon={icon}
          cardIcon={cardIcon}
          openResultModal={openResultModal}
          disableLoading={disableLoading}
        />
      </Grid>
      <Grid item container xs={2} sx={{ marginX: 2.5 }} justifyContent="center">
        <Player
          openProfile={() => setOpenProfile(true)}
          color="#FF3D00"
          avatar={avatar3}
          playerIndex={3}
          diceRoller={diceRoller}
          face={face}
          isRolling={isRolling}
          players={players}
          playerTurn={playerTurn}
          botBuyingIndustry={botBuyingIndustry}
          botBuyingShares={botBuyingShares}
          botSellingIndustry={botSellingIndustry}
          botSellingShares={botSellingShares}
          icon={icon}
          cardIcon={cardIcon}
          openResultModal={openResultModal}
          disableLoading={disableLoading}
        />
      </Grid>
    </Grid>
  );
};

export default Players;
